import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import i18n from 'src/i18n';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isMobile from 'ismobilejs';

import signInViewModel from 'src/components/Header/component/SignIn/viewModel';
import playerViewModel from 'src/components/Player/viewModel';
import { SuccessToast, ErrorToast } from 'src/libs/toast';
import PlayerAPIs from '../../apis';
import playerStore from '../../store';

dayjs.extend(duration);

class ChapterViewModel {
  @observable id;
  @observable cid;
  @observable priority;
  @observable popularity;
  @observable isFavorite;
  @observable name;
  @observable type;
  @observable ceilDuration;
  @observable lastAt;
  @observable document;


  constructor(props) {
    this.props = props;
    this.playerAPIs = new PlayerAPIs({ emitter: props.emitter });
    makeObservable(this);

    this.deserialize(props);
  }

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------

  @action
  deserialize = (props) => {
    const { data, emitter } = props;
    const {
      id,
      cid,
      priority,
      popularity,
      isFavorite,
      name,
      type,
      ceilDuration,
      lastAt,
      document
    } = data;

    this.id = id;
    this.cid = cid;
    this.priority = priority;
    this.popularity = popularity;
    this.isFavorite = isFavorite;
    this.name = name;
    this.type = type;
    this.ceilDuration = ceilDuration;
    this.lastAt = lastAt;
    this.document = document;

    this.emitter = emitter;
  }

  @action
  didMount = () => {
    playerViewModel.addListener('listFavorite', this.onFavorite);
  }

  @action
  willUnmount = () => {
    playerViewModel.removeListener('listFavorite', this.onFavorite);
  }

  @action
  onFavClick = async (e) => {
    e.stopPropagation();
    try {
      if (!playerStore.profile?.id) {
        SuccessToast(i18n.t('common_login_unlock'));
        if (!playerStore.isPaused) {
          this.onStop();
        }
        setTimeout(() => {
          signInViewModel.handleOpen();
        }, 500);
        return;
      }

      await this.playerAPIs.handlerFavoriteAPI({
        id: playerStore.profile.id,
        chapterId: this.id
      });
      runInAction(() => {
        this.isFavorite = !this.isFavorite;
      });

    } catch (error) {
      console.error(error);
    }
  }

  @action
  onFavorite = (data) => {
    const { id, isFavorite } = data;

    if (id === this.id) {
      this.isFavorite = isFavorite;
    }
  }

  onManuscriptClick = (e) => {
    e.stopPropagation();
    if (playerStore.isBigScreen) {
      playerStore.isBigScreen = false;
    }
    this.toggleOverflow();
  }

  toggleOverflow() {
    const doc = document.documentElement;
    const overflow = playerStore.isBigScreen ? 'hidden' : 'auto';

    doc.style.overflow = overflow;
  }

  // 當前單集是 video.
  @computed
  get isNotVideo() {
    return this.type !== 'Video';
  }

  // 文稿頁 link.
  @computed
  get chapterManuscriptLink() {
    return `/course/${this.cid}/chapter/${this.id}`;
  }

  // 計算秒數.
  @computed
  get calcDuration() {
    const hasHours = this.ceilDuration >= 3600;

    if (hasHours) {
      return dayjs
        .duration(this.ceilDuration, 'seconds')
        .format('HH:mm:ss');
    }

    return dayjs
      .duration(this.ceilDuration, 'seconds')
      .format('mm:ss');
  }

  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------
  // --------------------------------------------------------------------------

  @action
  onSetChapterId = async () => {
    if (playerStore.chapter.id === this.id) {
      // player 重新播放.
      playerStore.currentTime = 0;
      playerStore.mediaRef.current.seekTo(0, 'seconds');
      // 開啟排程.
      this.playerAPIs.setIntervals();
    } else {
      await this.playerAPIs.handlerLearnedLastAPI();
      await this.playerAPIs.handlerGetChapterAPI(this.id);
      await this.playerAPIs.handlerGetPlayerUrlAPI(this.id);
    }
    if (isMobile(window.navigator).phone && playerStore.chapter?.type === 'Video') {
      const target = document.querySelector('.big-screen-enter-done');
      if (target) {
        target.scrollTo(0, 0);
      }
    }
  };
}

export default ChapterViewModel;
